/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/typography.css';
import './src/styles/global.css';
import './src/styles/tiles.css';
import './src/styles/alignment.css';

import './src/styles/bootstrap-modal.css';
import './src/styles/bootstrap-grid.css';

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
  }
}